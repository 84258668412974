import { FC, useState } from 'react'
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'src/components/01-atoms/Button'
import MonthYearSelector from 'src/components/01-atoms/MonthYearSelector'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import Calendar, { ICalendarProps } from 'src/components/02-molecules/Calendar'
import { IManifestCalendarInterval } from 'src/graphql/types'

interface ICalendarPopoverProps extends Omit<ICalendarProps, 'className'>, IModalProps {
  /**
   * Whether or not the calendar data is loading.
   */
  loading?: boolean

  /**
   * Classes to pass to the containing element.
   */
  className?: string

  /**
   * Method to call when the month and year changes.
   */
  handleMonthChange?: CallableFunction

  /**
   * Whether to display calendar in Monthly or Weekly format
   */
  interval?: IManifestCalendarInterval

  /**
   * Whether or not to allow Saturday shipping.
   */
  allowSaturdayShipping?: boolean
}

const CalendarPopover: FC<ICalendarPopoverProps> = ({
  selectedDate = new Date(),
  dates,
  dateLinkObject = { pathname: '/' },
  loading = false,
  disabled = false,
  className = '',
  interval,
  allowSaturdayShipping = false,
  handleMonthChange = () => {},
  ...modalProps
}) => {
  const [ isOpen, setIsOpen ] = useState( false )

  return (
    <div className={className}>
      <Button
        className="h-8 w-8 center"
        outline
        onClick={() => setIsOpen( true )}
        disabled={disabled}
        data-testid="calendar-popover-trigger"
      >
        <FontAwesomeIcon icon={faCalendarDay} size="lg" />
        <span className="sr-only">Open Calendar Popover</span>
      </Button>
      <Modal
        {...modalProps}
        title="Select a Date"
        open={isOpen}
        handleClose={() => setIsOpen( false )}
        contentClassName="md:min-w-80 lg:top-1/2 overflow-y-visible"
        showCloseButton
        takeoverOnMobile
      >
        <div data-testid="calendar-popover-content">
          <MonthYearSelector
            selectedDate={selectedDate}
            handleChange={handleMonthChange}
            className="mb-2"
          />
          <Calendar
            selectedDate={selectedDate}
            dates={dates}
            dateLinkObject={dateLinkObject}
            handleClickOnDate={() => {
              setIsOpen( false )
            }}
            disabled={disabled}
            loading={loading}
            interval={interval}
            withSaturdayShipping={allowSaturdayShipping}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CalendarPopover
