import { FC } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import InlineLink from 'src/components/01-atoms/InlineLink'
import SectionCard from 'src/components/01-atoms/SectionCard'

import { IShippingLabel } from 'src/graphql/types'
import { longFormatDayAndDate } from 'src/utils/helpers/date'
import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcSuborderLink } from 'src/utils/gbmcLink'
import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import classNames from 'classnames'
import ManifestPackageContents from '../ManifestPackageContents'

export interface IPackageSummaryProps {
  id: string
  toShipOn: Date
  deliveryDate: Date
  lineItems: {
    id: string
    quantity: number
    name: string
    productOptions?: string[]
    specialInstructions?: string | null
    sku?: string[] | string | null
  }[]
  currentShippingLabel?: IShippingLabel | null
  isCanceled?: boolean
  showLinkToGbmc?: boolean
}

const PackageSummary: FC<IPackageSummaryProps> = ({
  id,
  toShipOn,
  deliveryDate,
  lineItems,
  currentShippingLabel,
  isCanceled = false,
  showLinkToGbmc = false,
}) => {
  const { makeLinkUrls } = useAppParams()

  const title = (
    <div className="flex justify-between">
      <InlineLink to={makeLinkUrls().packageDetail( id )}>Package {id}</InlineLink>
      <div className="flex gap-4 items-center">
        {showLinkToGbmc && (
          <InlineLink className="text-xs" data-testid="gbmc-link" href={gbmcSuborderLink( id )}>
            View in GBMC
          </InlineLink>
        )}
        {isCanceled && <PackageTag packageType={PackageType.CANCELED} />}
      </div>
    </div>
  )

  const { trackingCode, trackingLink, serviceName } = currentShippingLabel ?? {}

  return (
    <SectionCard size="small" title={title} className={classNames({ 'bg-yellow-100': isCanceled })}>
      <Grid>
        <div className="col-span-12 md:col-span-6">
          <div>
            <span className="font-bold">Ship Date:</span> {longFormatDayAndDate( toShipOn )}
          </div>
          <div>
            <span className="inline font-bold">Delivery Date:</span>{' '}
            {longFormatDayAndDate( deliveryDate )}
          </div>
          <div>
            <span className="inline font-bold">Carrier &amp; Speed:</span> {serviceName}
          </div>
          <div>
            <span className="inline font-bold">Shipping Label:</span>{' '}
            {trackingLink && trackingCode ? (
              <InlineLink to={trackingLink} target="_blank">
                {trackingCode}
              </InlineLink>
            ) : (
              'Pending'
            )}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          {lineItems && lineItems.length > 0 && (
            <>
              <div className="font-bold">Products:</div>
              <div className="pl-2">
                <ManifestPackageContents
                  lineItems={lineItems}
                  lineItemsClasses="text-sm"
                  productOptionsClasses="text-xs"
                />
              </div>
            </>
          )}
        </div>
      </Grid>
    </SectionCard>
  )
}

export default PackageSummary
