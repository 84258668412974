import classNames from 'classnames'
import { kebabCase } from 'lodash'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

interface IManifestPackageContentsProps {
  /**
   * The line items to display.
   */
  lineItems: {
    id: string
    quantity: number
    name: string
    productOptions?: string[]
    specialInstructions?: string | null
    sku?: string[] | string | null
  }[]

  /**
   * The classes to apply to the line items.
   */
  lineItemsClasses?: string

  /**
   * The classes to apply to the product options.
   */
  productOptionsClasses?: string

  /**
   * Whether or not the product options are being fetched.
   */
  fetchingProductOptions?: boolean
}

const ManifestPackageContents: FC<IManifestPackageContentsProps> = ({
  lineItems,
  lineItemsClasses,
  productOptionsClasses,
  fetchingProductOptions = false,
}) => (
  <ul className={classNames( 'leading-relaxed', lineItemsClasses )}>
    {lineItems.map(( li ) => (
      <li key={`product-${li.id}`} data-testid={`product-${li.id}`}>
        <span className="font-bold">{li.quantity}</span> - {li.name}
        {( fetchingProductOptions || li.productOptions || li.specialInstructions ) && (
          <div className={productOptionsClasses}>
            {fetchingProductOptions ? (
              <Skeleton />
            ) : (
              <ul className="pl-6" data-testid={`product-${li.id}-options`}>
                {li.productOptions?.map(( v ) => <li key={`product-option-${kebabCase( v )}`}>{v}</li> )}
              </ul>
            )}
            {li.specialInstructions && (
              <div className="my-1 ml-2 pl-2 border-l-4 border-gb-gray-500">
                <span className="font-bold">Instructions:&nbsp;</span>
                {li.specialInstructions}
              </div>
            )}
          </div>
        )}
      </li>
    ))}
  </ul>
)

export default ManifestPackageContents
