import * as z from 'zod'

/**
 * Make sure this schema matches the IOrderForm interface in ./types.ts.
 */
const requiredFields = z
  .object({
    purchaserFirstName: z.string().regex( /\w+/, { message: 'Please enter a valid first name.' }),
    purchaserLastName: z.string().regex( /\w+/, { message: 'Please enter a valid last name.' }),
    purchaserPhone: z.string().regex( /^\d{10}$/, { message: 'Please enter a valid phone number.' }),
    purchaserEmail: z
      .string({ invalid_type_error: 'Email is required.', required_error: 'Email is required.' })
      .email({ message: 'Please enter a valid email address.' }),
    deliveryFirstName: z.string({
      required_error: 'First name is required.',
      invalid_type_error: 'Please enter a valid first name.',
    }),
    deliveryLastName: z.string({
      required_error: 'Last name is required.',
      invalid_type_error: 'Please enter a valid last name.',
    }),
    deliveryStreet1: z.string({
      required_error: 'Address Line 1 is required.',
      invalid_type_error: 'Please enter a valid address.',
    }),
    deliveryCity: z.string({
      required_error: 'City is required.',
      invalid_type_error: 'Please enter a valid city.',
    }),
    deliveryState: z.string({
      required_error: 'State is required.',
      invalid_type_error: 'State is required.',
    }),
    deliveryPostalCode: z
      .string({
        required_error: 'Zip code is required.',
        invalid_type_error: 'Please enter a valid zip code.',
      })
      .regex( /^\d{5}(?:-\d{4})?$/, { message: 'Please enter a valid zip code.' }),
  })
  .required()

const optionalFields = z.object({
  recipientEmail: z
    .string()
    .regex( /(?:^$|^(?:[^\s@,]+@[^\s@,]+\.[^\s@,]+)$)/, {
      message: 'Please enter a valid email address.',
    })
    .default( '' )
    .nullable()
    .optional(),
  deliveryCompany: z.string().default( '' ).nullable().optional(),
  deliveryPhone: z.string().default( '' ).nullable().optional(),
  deliveryStreet2: z.string().default( '' ).nullable().optional(),
  giftMessage: z.string().max( 280 ).default( '' ).nullable().optional(),
  giftMessageFrom: z.string().default( '' ).nullable().optional(),
  inStorePayment: z.boolean().default( false ).nullable().optional(),
})

const defaultFormSchema = requiredFields.merge( optionalFields )

export const requiresCreditCardSchema = requiredFields.merge( optionalFields ).merge(
  z
    .object({
      nameOnCard: z
        .string({
          required_error: 'Name is required.',
          invalid_type_error: 'Please enter a valid name.',
        })
        .regex( /\w+/, { message: 'Please enter a valid name.' }),
      paymentPhone: z.string().regex( /^\d{10}$/, { message: 'Please enter a valid phone number.' }),
    })
    .required()
)

export default defaultFormSchema
