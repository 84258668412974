import { FC } from 'react'
import classNames from 'classnames'
import { To } from 'react-router-dom'

import Grid from 'src/components/01-atoms/Grid'
import SectionCard from 'src/components/01-atoms/SectionCard'
import DetailPageHeader from 'src/components/02-molecules/DetailPageHeader'
import DetailsList from 'src/components/02-molecules/DetailsList'
import PackageSummary from 'src/components/02-molecules/PackageSummary'
import RestrictedAddress from 'src/components/02-molecules/RestrictedAddress'

import { IShippingLabel } from 'src/graphql/types'
import { CountryCode } from 'src/utils/countryNameMap'

interface IOrderDetailProps {
  id: string
  backLink?: To
  purchaser: {
    id?: string
    name: string
  }
  recipient: string
  processedAt: Date
  deliveryCompany?: string
  deliveryStreet1: string
  deliveryStreet2?: string
  deliveryCity: string
  deliveryState: string
  deliveryPostalCode: string
  deliveryCountry: string
  giftMessage?: {
    body: string
    from?: string
  }
  packages: {
    id: string
    toShipOn: Date
    deliveryDate: Date
    lineItems: {
      id: string
      quantity: number
      name: string
      productOptions?: string[]
      specialInstructions?: string | null
      sku?: string[] | string | null
    }[]
    currentShippingLabel?: IShippingLabel | null
    isCanceled?: boolean
    isWhitelabel?: boolean
  }[]
  subtotalInCents: number
  shippingSubtotalInCents: number
  taxInCents: number
  discountInCents: number
  totalInCents: number
  showLinkToGbmc?: boolean
}

const OrderDetail: FC<IOrderDetailProps> = ({
  id,
  backLink = '',
  purchaser,
  recipient,
  processedAt,
  deliveryCompany,
  deliveryStreet1,
  deliveryStreet2,
  deliveryCity,
  deliveryState,
  deliveryPostalCode,
  deliveryCountry,
  giftMessage,
  packages,
  subtotalInCents,
  shippingSubtotalInCents,
  taxInCents,
  discountInCents,
  totalInCents,
  showLinkToGbmc = false,
}) => {
  const allPackagesCanceled = packages.every(( pkg ) => pkg.isCanceled )
  const allPackagesWhitelabel = packages.every(( pkg ) => pkg.isWhitelabel )
  const formatCurrency = ( amount: number ) => `$${( amount / 100 ).toFixed( 2 )}`

  return (
    <>
      <DetailPageHeader
        id={id}
        type="order"
        backLink={{ url: backLink }}
        isCanceled={allPackagesCanceled}
        showLinkToGbmc={showLinkToGbmc}
        placedOn={processedAt}
      />
      <Grid>
        <SectionCard
          title="Details"
          className={classNames( 'col-span-12 md:col-span-6 lg:col-span-4' )}
        >
          <div data-testid="section-order-details">
            <DetailsList
              variant="borderless"
              list={[
                { label: 'Purchaser', value: purchaser.name },
                { label: 'Recipient', value: recipient },
                {
                  label: 'Ship To',
                  value: (
                    <RestrictedAddress
                      address={{
                        company: deliveryCompany,
                        street1: deliveryStreet1,
                        street2: deliveryStreet2,
                        city: deliveryCity,
                        state: deliveryState,
                        postalCode: deliveryPostalCode,
                        countryCode: deliveryCountry as CountryCode,
                      }}
                      showFullAddress={allPackagesWhitelabel}
                    />
                  ),
                },
                {
                  label: 'Order Totals',
                  value: (
                    <table className="w-full tabular-nums">
                      <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td className="text-right">{formatCurrency( subtotalInCents )}</td>
                        </tr>
                        {shippingSubtotalInCents > 0 && (
                          <tr>
                            <td>Shipping</td>
                            <td className="text-right">
                              {formatCurrency( shippingSubtotalInCents )}
                            </td>
                          </tr>
                        )}
                        {taxInCents > 0 && (
                          <tr>
                            <td>Tax</td>
                            <td className="text-right">{formatCurrency( taxInCents )}</td>
                          </tr>
                        )}
                        {discountInCents > 0 && (
                          <tr>
                            <td>Discount</td>
                            <td className="text-right">-{formatCurrency( discountInCents )}</td>
                          </tr>
                        )}
                        <tr className="font-bold">
                          <td>Total</td>
                          <td className="text-right">{formatCurrency( totalInCents )}</td>
                        </tr>
                      </tbody>
                    </table>
                  ),
                },
              ]}
            />
          </div>
        </SectionCard>
        <div className="col-span-12 md:col-span-6 lg:col-span-8 flex flex-col gap-4">
          {giftMessage && (
            <SectionCard title="Gift Message">
              <div data-testid="section-gift-message">
                <p>{giftMessage?.body}</p>
                {giftMessage.from && <p>&mdash; {giftMessage.from}</p>}
              </div>
            </SectionCard>
          )}
          {packages.length > 0 && (
            <div data-testid="section-packages" className="flex flex-col gap-4">
              {packages.map(( pkg ) => (
                <PackageSummary key={pkg.id} {...pkg} showLinkToGbmc={showLinkToGbmc} />
              ))}
            </div>
          )}
        </div>
      </Grid>
    </>
  )
}

export default OrderDetail
