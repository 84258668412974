import { FC } from 'react'
import { faLocationExclamation } from '@fortawesome/pro-duotone-svg-icons'

import Button from 'src/components/01-atoms/Button'
import CircleIcon from 'src/components/01-atoms/CircleIcon'
import InlineLink from 'src/components/01-atoms/InlineLink'
import Address from 'src/components/01-atoms/Address'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'

import { IAddress } from 'src/utils/types/IAddress'

export interface IAddressOverrideModalProps extends IModalProps {
  /**
   * The address given by the customer.
   */
  address: IAddress

  /**
   * The method to call when the address is selected and the modal is closed.
   */
  handleApprove?: () => void

  /**
   * The method to call to close the modal.
   */
  handleClose?: () => void
}

const AddressOverrideModal: FC<IAddressOverrideModalProps> = ({
  address,
  handleApprove = () => {},
  handleClose = () => {},
  ...modalProps
}) => (
  <Modal
    {...modalProps}
    showCloseButton
    closeOnBlur
    takeoverOnMobile
    handleClose={handleClose}
    contentClassName="sm:max-w-125 md:max-w-125 lg:max-w-125 pb-9"
  >
    <div
      data-testid="address-override-modal"
      className="flex flex-col center gap-y-6 text-sm lg:w-full"
    >
      <div className="flex flex-col gap-2 items-center text-center">
        <CircleIcon size="large" color="red" icons={[ faLocationExclamation ]} />
        <div className="text-gb-gray-900 text-lg font-semibold leading-tight">Invalid Address</div>
        <div>Are you sure you want to use the following?</div>
      </div>
      <div className="text-center">
        <Address address={address} />
      </div>
      <div className="flex flex-col center gap-6 w-full md:w-10/12">
        <Button
          className="center py-3 w-full"
          onClick={() => {
            handleApprove()
            handleClose()
          }}
        >
          Yes, use this address
        </Button>
        <InlineLink className="text-center" onClick={handleClose}>
          No, I need to re-enter the address
        </InlineLink>
      </div>
    </div>
  </Modal>
)

export default AddressOverrideModal
