import { FC } from 'react'

import Grid from 'src/components/01-atoms/Grid'
import TextInput from 'src/components/01-atoms/TextInput'

import { FormTextInputProps } from 'src/utils/types/forms'

interface IPurchaserInfoForm {
  /**
   * Props for the email input element, likely from `react-hook-form` register method, but can be an object of string key => value pairs.
   */
  fieldProps?: {
    firstName: FormTextInputProps
    lastName: FormTextInputProps
    phone: FormTextInputProps
    email: FormTextInputProps
  }
}

const PurchaserInfoForm: FC<IPurchaserInfoForm> = ({ fieldProps }) => (
  <Grid className="gap-y-0">
    <TextInput
      wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
      labelText="First Name *"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.firstName}
    />
    <TextInput
      wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
      labelText="Last Name *"
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.lastName}
    />
    <TextInput
      wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
      type="tel"
      labelText="Phone *"
      showLabel
      outline
      reduceLayoutShift
      maxLength={10}
      {...fieldProps?.phone}
    />
    <TextInput
      wrapperClassName="col-span-12 md:col-span-6 xl:col-span-4"
      type="email"
      labelText="Email *"
      helperText="Sends email confirmation and order information when the package ships."
      showLabel
      outline
      reduceLayoutShift
      {...fieldProps?.email}
    />
  </Grid>
)

export default PurchaserInfoForm
