import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  ISO8601Date: { input: any; output: any }
  ISO8601DateTime: { input: any; output: any }
}

export enum IAccessLogEntity {
  MDASH_ACCOUNT = 'MDASH_ACCOUNT',
  MERCHANT_USER = 'MERCHANT_USER',
}

export type IAccountAggregate = {
  __typename?: 'AccountAggregate'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  value: Scalars['Int']['output']
}

/** Autogenerated input type of AddPromotionalCodeToOrder */
export type IAddPromotionalCodeToOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  code: Scalars['String']['input']
  orderId: Scalars['ID']['input']
}

/** Autogenerated return type of AddPromotionalCodeToOrder */
export type IAddPromotionalCodeToOrderPayload = {
  __typename?: 'AddPromotionalCodeToOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

export type IAdminUser = {
  __typename?: 'AdminUser'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type IAppliedPromotion = {
  __typename?: 'AppliedPromotion'
  amountInCents?: Maybe<Scalars['Int']['output']>
  code?: Maybe<Scalars['String']['output']>
}

export type IAssignPackagesToFacilityError = {
  __typename?: 'AssignPackagesToFacilityError'
  message: Scalars['String']['output']
  packageId?: Maybe<Scalars['ID']['output']>
}

/** Autogenerated input type of AssignPackagesToFacility */
export type IAssignPackagesToFacilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  facilityId?: InputMaybe<Scalars['ID']['input']>
  facilityType?: InputMaybe<IFacilityType>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageIds: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of AssignPackagesToFacility */
export type IAssignPackagesToFacilityPayload = {
  __typename?: 'AssignPackagesToFacilityPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<IAssignPackagesToFacilityError>>
  packages?: Maybe<Array<IPackage>>
}

/** Autogenerated input type of AssignProductToTaxonomy */
export type IAssignProductToTaxonomyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId: Scalars['ID']['input']
  productId: Scalars['ID']['input']
  taxonomyId: Scalars['ID']['input']
}

/** Autogenerated return type of AssignProductToTaxonomy */
export type IAssignProductToTaxonomyPayload = {
  __typename?: 'AssignProductToTaxonomyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  productTaxonomy: IProductTaxonomy
}

export type IBatchPrintAction = {
  __typename?: 'BatchPrintAction'
  fulfillmentSheetsPartiallyPrinted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  length: Scalars['Int']['output']
  pickListsPartiallyPrinted: Scalars['Boolean']['output']
  shippingLabelsPartiallyPrinted: Scalars['Boolean']['output']
}

export type IBatchPrintActivityAggregate = {
  __typename?: 'BatchPrintActivityAggregate'
  labelsCount: Scalars['Int']['output']
  toShipOn: Scalars['ISO8601Date']['output']
}

/** Autogenerated input type of BatchPrint */
export type IBatchPrintInput = {
  batchPrintActionId?: InputMaybe<Scalars['ID']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  corporateOrderCompanyNames?: InputMaybe<Array<Scalars['String']['input']>>
  facilityId?: InputMaybe<Scalars['ID']['input']>
  facilityType?: InputMaybe<IFacilityType>
  includePrintedPackages?: InputMaybe<Scalars['Boolean']['input']>
  labelPrintStatus?: InputMaybe<IShippingLabelStatus>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  orderTypes?: InputMaybe<Array<IOrderType>>
  packageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  printedPackingSlipForSuborderIds?: InputMaybe<Array<Scalars['ID']['input']>>
  printedPickList?: InputMaybe<Scalars['Boolean']['input']>
  printedShippingLabelIds?: InputMaybe<Array<Scalars['ID']['input']>>
  productCountInPackages?: InputMaybe<Array<Scalars['Int']['input']>>
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>
  query?: InputMaybe<Scalars['String']['input']>
  recipientCountries?: InputMaybe<Array<Scalars['String']['input']>>
  recipientStates?: InputMaybe<Array<Scalars['String']['input']>>
  shippingSpeeds?: InputMaybe<Array<Scalars['String']['input']>>
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>
  sortField?: InputMaybe<IManifestPackageSort>
  toShipOn: Scalars['String']['input']
  /**
   * Pass true to clear timestamp of entities provided in
   * printedShippingLabelIds or printedPackingSlipForSuborderIds
   *
   */
  undoMutation?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of BatchPrint */
export type IBatchPrintPayload = {
  __typename?: 'BatchPrintPayload'
  batchPrintAction: IBatchPrintAction
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  manifestPackages: IManifestTable
  markedPackingSlipForPackages: Array<IPackage>
  markedShippingLabels: Array<IShippingLabel>
  packagesRequireDiscard: IManifestTable
  packagesRequireNewLabels: IManifestTable
  subproductSummary: ISubproductSummary
}

/** Autogenerated input type of CancelPackage */
export type ICancelPackageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageId: Scalars['ID']['input']
}

/** Autogenerated return type of CancelPackage */
export type ICancelPackagePayload = {
  __typename?: 'CancelPackagePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  package?: Maybe<IPackage>
}

export type ICartItem = {
  __typename?: 'CartItem'
  id: Scalars['ID']['output']
  product: IProduct
  quantity?: Maybe<Scalars['Int']['output']>
  selectedProductOptions: Array<ISelectedProductOption>
  specialInstructions?: Maybe<Scalars['String']['output']>
  subtotalInCents: Scalars['Int']['output']
  unitPriceInCents: Scalars['Int']['output']
  weight?: Maybe<Scalars['Float']['output']>
}

/** Autogenerated input type of CopyPackage */
export type ICopyPackageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageId: Scalars['ID']['input']
}

/** Autogenerated return type of CopyPackage */
export type ICopyPackagePayload = {
  __typename?: 'CopyPackagePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  orderId?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of CreateAutoAssignmentRule */
export type ICreateAutoAssignmentRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryState?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<IFacilityAutoAssignmentRuleMode>
  productId?: InputMaybe<Scalars['ID']['input']>
  shipperFacilityId?: InputMaybe<Scalars['ID']['input']>
  shipperId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateAutoAssignmentRule */
export type ICreateAutoAssignmentRulePayload = {
  __typename?: 'CreateAutoAssignmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shipper?: Maybe<IShipper>
}

/** Autogenerated input type of CreateCartItem */
export type ICreateCartItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
  productId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateCartItem */
export type ICreateCartItemPayload = {
  __typename?: 'CreateCartItemPayload'
  cartItem?: Maybe<ICartItem>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of CreateOrder */
export type ICreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

/** Autogenerated return type of CreateOrder */
export type ICreateOrderPayload = {
  __typename?: 'CreateOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

/** Autogenerated input type of CreateTaxonomy */
export type ICreateTaxonomyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

/** Autogenerated return type of CreateTaxonomy */
export type ICreateTaxonomyPayload = {
  __typename?: 'CreateTaxonomyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  mdashAccount: IMDashAccount
}

export type ICustomerChoice = {
  __typename?: 'CustomerChoice'
  name: Scalars['String']['output']
  position: Scalars['Int']['output']
}

export type IDailyPrintActivityAggregate = {
  __typename?: 'DailyPrintActivityAggregate'
  children: Array<IAccountAggregate>
  name: Scalars['String']['output']
}

/** Autogenerated input type of DiscardPackagesShippingLabels */
export type IDiscardPackagesShippingLabelsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  packageIds: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of DiscardPackagesShippingLabels */
export type IDiscardPackagesShippingLabelsPayload = {
  __typename?: 'DiscardPackagesShippingLabelsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shippingLabels?: Maybe<Array<IShippingLabel>>
}

/** Autogenerated input type of DiscardShippingLabel */
export type IDiscardShippingLabelInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  shippingLabelIds: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of DiscardShippingLabel */
export type IDiscardShippingLabelPayload = {
  __typename?: 'DiscardShippingLabelPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shippingLabels?: Maybe<Array<IShippingLabel>>
}

/** Autogenerated input type of EditDeliveryDate */
export type IEditDeliveryDateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryOn: Scalars['String']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageId: Scalars['ID']['input']
}

/** Autogenerated return type of EditDeliveryDate */
export type IEditDeliveryDatePayload = {
  __typename?: 'EditDeliveryDatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  overLimits?: Maybe<Array<Scalars['String']['output']>>
  package?: Maybe<IPackage>
}

/** Autogenerated input type of EditGiftMessage */
export type IEditGiftMessageInput = {
  body: Scalars['String']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  from: Scalars['String']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageId: Scalars['ID']['input']
}

/** Autogenerated return type of EditGiftMessage */
export type IEditGiftMessagePayload = {
  __typename?: 'EditGiftMessagePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  package?: Maybe<IPackage>
}

export type IExtendedMetabaseReport = {
  __typename?: 'ExtendedMetabaseReport'
  breadcrumbs?: Maybe<Array<IMetabaseReportBreadcrumb>>
  calculatedInput?: Maybe<Array<IMetabaseReportInput>>
  dashboardId?: Maybe<Scalars['ID']['output']>
  enableDateFilters?: Maybe<Scalars['Boolean']['output']>
  enableMerchantsFilter?: Maybe<Scalars['Boolean']['output']>
  endDate?: Maybe<Scalars['ISO8601Date']['output']>
  error?: Maybe<Scalars['String']['output']>
  htmlControlInput?: Maybe<Array<IMetabaseReportInput>>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['ISO8601Date']['output']>
  uiFlags?: Maybe<Array<IMetabaseReportUiFlag>>
  url?: Maybe<Scalars['String']['output']>
  useMetabase?: Maybe<Scalars['Boolean']['output']>
  userInput?: Maybe<Array<IMetabaseReportInput>>
}

export type IFacilityAutoAssignmentRule = {
  __typename?: 'FacilityAutoAssignmentRule'
  deliveryState?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mode: IFacilityAutoAssignmentRuleMode
  productId?: Maybe<Scalars['ID']['output']>
  shipperFacilityId: Scalars['ID']['output']
}

export enum IFacilityAutoAssignmentRuleMode {
  ALLOWLIST = 'allowlist',
  BLOCKLIST = 'blocklist',
}

export enum IFacilityType {
  PICK_UP_LOCATION = 'PICK_UP_LOCATION',
  SHIPPING_FACILITY = 'SHIPPING_FACILITY',
}

export type IGiftCard = {
  __typename?: 'GiftCard'
  amountInCents: Scalars['Int']['output']
  code: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type IGiftMessage = {
  __typename?: 'GiftMessage'
  body: Scalars['String']['output']
  from: Scalars['String']['output']
}

export type IIdentity = {
  __typename?: 'Identity'
  adminUser?: Maybe<IAdminUser>
  mdashAccount?: Maybe<IMDashAccount>
  merchantUser?: Maybe<IMerchantUser>
}

export type IIdentityMdashAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IInvoiceAdjustment = {
  __typename?: 'InvoiceAdjustment'
  /** Total amount of the adjustment */
  amount?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  details?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  orderId?: Maybe<Scalars['ID']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
}

export type IInvoiceCancellation = {
  __typename?: 'InvoiceCancellation'
  cancelledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  orderId?: Maybe<Scalars['ID']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  shippedOn?: Maybe<Scalars['ISO8601Date']['output']>
  /** Total invoiced amount */
  totalAdjustment?: Maybe<Scalars['String']['output']>
}

export type IInvoiceGiftCardRedemption = {
  __typename?: 'InvoiceGiftCardRedemption'
  chargedVia: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  giftCardCode: Scalars['String']['output']
  id: Scalars['ID']['output']
  orderId?: Maybe<Scalars['ID']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  redeemedAt: Scalars['ISO8601DateTime']['output']
  totalAdjustment: Scalars['String']['output']
}

export type IInvoiceMissedShipment = {
  __typename?: 'InvoiceMissedShipment'
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  missedShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  newShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  newShipSpeed?: Maybe<Scalars['String']['output']>
  orderId?: Maybe<Scalars['ID']['output']>
  originalShipSpeed?: Maybe<Scalars['String']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  /** Total invoiced amount */
  totalAdjustment?: Maybe<Scalars['String']['output']>
}

export type IInvoicePromotion = {
  __typename?: 'InvoicePromotion'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  orderId?: Maybe<Scalars['ID']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  promoCode?: Maybe<Scalars['String']['output']>
  /** Total invoiced amount */
  totalAdjustment?: Maybe<Scalars['String']['output']>
}

export type IInvoiceRefund = {
  __typename?: 'InvoiceRefund'
  comment?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  lineItems?: Maybe<Array<ILineItem>>
  orderId?: Maybe<Scalars['ID']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  reason?: Maybe<Scalars['String']['output']>
  refundsShipping: Scalars['Boolean']['output']
  responsibleParty?: Maybe<Scalars['String']['output']>
  shippedOn?: Maybe<Scalars['ISO8601Date']['output']>
  /** Total invoiced amount */
  totalAdjustment?: Maybe<Scalars['String']['output']>
}

export type IInvoiceReshipment = {
  __typename?: 'InvoiceReshipment'
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lineItems?: Maybe<Array<ILineItem>>
  newShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  orderId?: Maybe<Scalars['ID']['output']>
  originalShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  /** Total invoiced amount for the product */
  productAdjustment?: Maybe<Scalars['String']['output']>
  reason?: Maybe<Scalars['String']['output']>
  reasonId?: Maybe<Scalars['ID']['output']>
  /** Total invoiced amount for shipping */
  shippingAdjustment?: Maybe<Scalars['String']['output']>
  /** Total invoiced amount */
  totalAdjustment?: Maybe<Scalars['String']['output']>
}

export type IIssueInterface = {
  adjustmentsCost: Scalars['String']['output']
  adjustmentsInCents: Scalars['Int']['output']
  category: Scalars['String']['output']
  label: Scalars['String']['output']
  numberOfIssues: Scalars['Int']['output']
  numberOfPackages: Scalars['Int']['output']
  percentOfPackagesWithIssues: Scalars['Float']['output']
}

export type ILineItem = {
  __typename?: 'LineItem'
  customerChoices: Array<ICustomerChoice>
  id: Scalars['ID']['output']
  merchant: IMerchant
  name: Scalars['String']['output']
  product?: Maybe<IProduct>
  quantityOrWeight: Scalars['Float']['output']
  specialInstructions?: Maybe<Scalars['String']['output']>
}

export type ILineItemCustomerChoice = {
  __typename?: 'LineItemCustomerChoice'
  customerChoices: Array<ICustomerChoice>
  id: Scalars['ID']['output']
  merchantSkus: Array<IMerchantSku>
  suborderId: Scalars['ID']['output']
}

/** Autogenerated input type of Login */
export type ILoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

/** Autogenerated return type of Login */
export type ILoginPayload = {
  __typename?: 'LoginPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  identity?: Maybe<IIdentity>
}

/** Autogenerated input type of Logout */
export type ILogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Logout */
export type ILogoutPayload = {
  __typename?: 'LogoutPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type IMDashAccount = {
  __typename?: 'MDashAccount'
  defaultMerchantId?: Maybe<Scalars['ID']['output']>
  displayPackageBarcode: Scalars['Boolean']['output']
  displaySku: Scalars['Boolean']['output']
  dryIceUsePlaceholder: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isMultiPickUpLocation: Scalars['Boolean']['output']
  isMultiShippingFacility: Scalars['Boolean']['output']
  isPickUpEnabled: Scalars['Boolean']['output']
  merchantNotificationContacts?: Maybe<Array<IMerchantNotificationContact>>
  merchantUserAccessLogs: Array<IMerchantUserAccessLogAggregate>
  merchants?: Maybe<Array<IMerchant>>
  name: Scalars['String']['output']
  packingSlipVersion: Scalars['Int']['output']
  permissions: IMDashAccountPermissionSet
  productTaxonomies: Array<IProductTaxonomy>
  products: Array<IProduct>
  shippers?: Maybe<Array<IShipper>>
  shippingFacilities: Array<IShippingFacility>
  smsNotificationEnabled: Scalars['Boolean']['output']
  taxonomies: Array<ITaxonomy>
}

export type IMDashAccountProductsArgs = {
  forGuac?: InputMaybe<Scalars['Boolean']['input']>
}

export type IMDashAccountPermissionSet = {
  __typename?: 'MDashAccountPermissionSet'
  canDoAmDelivery?: Maybe<Scalars['Boolean']['output']>
  canOverridePrices?: Maybe<Scalars['Boolean']['output']>
  canPayInStore?: Maybe<Scalars['Boolean']['output']>
  canPrintThermalShippingLabels?: Maybe<Scalars['Boolean']['output']>
  canQueryPurchaserInfo?: Maybe<Scalars['Boolean']['output']>
  thermalShippingLabelsEnabledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/**
 * This field aggregates packages in a given manifest.
 * The aggregations are described in pairs of Category and Query
 * with their corresponding values.
 *
 * Example:
 * - Category: LABEL_PRINT_STATUS
 *   - PRINTED
 *   - READY_TO_PRINT
 *   - DO_NOT_SHIP
 * - Category: RECIPIENT_STATE
 *   - AK
 *   - CA
 *   - NY
 * - Category: SHIPPING_SPEED
 *   - FEDEX_GROUND
 *   - STANDARD_OVERNIGHT
 *   - PRIORITY_OVERNIGHT
 *
 * These pairs can then be fed back to filter the Manifests.
 * The Manifest supports multi-category filtering.
 *
 * Example:
 *
 * manifestPackage (
 *   labelPrintStatus: READY_TO_PRINT
 *   shippingSpeed: ["FEDEX_GROUND", "STANDARD_OVERNIGHT"]
 * )
 *
 */
export type IManifestAggregate = {
  __typename?: 'ManifestAggregate'
  /** Filter Category */
  category: IManifestFilterCategory
  /** Indentation Hint for FE grouping */
  indent?: Maybe<Scalars['Boolean']['output']>
  /** Human-friendly Filter Value */
  label?: Maybe<Scalars['String']['output']>
  /** Filter Value as stored in the backend */
  query?: Maybe<Scalars['String']['output']>
  taxonomyProducts?: Maybe<Array<IProduct>>
  /** Number of packages matching this specific filter query */
  value: Scalars['Int']['output']
}

export type IManifestCalendarDay = {
  __typename?: 'ManifestCalendarDay'
  toShipCount: Scalars['Int']['output']
  toShipOn: Scalars['ISO8601Date']['output']
}

export enum IManifestCalendarInterval {
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export type IManifestCarrierInfo = {
  __typename?: 'ManifestCarrierInfo'
  airPhoneNumbers?: Maybe<Array<Scalars['String']['output']>>
  airPickupHours?: Maybe<Scalars['String']['output']>
  airReferenceCode?: Maybe<Scalars['String']['output']>
  carrierName: Scalars['String']['output']
  groundPhoneNumbers?: Maybe<Array<Scalars['String']['output']>>
  groundPickupHours?: Maybe<Scalars['String']['output']>
  groundReferenceCode?: Maybe<Scalars['String']['output']>
  toShipOn: Scalars['ISO8601Date']['output']
}

export enum IManifestFilterCategory {
  CORPORATE_ORDER_COMPANY_NAMES = 'CORPORATE_ORDER_COMPANY_NAMES',
  LABEL_PRINT_STATUS = 'LABEL_PRINT_STATUS',
  ORDER_TYPES = 'ORDER_TYPES',
  PICK_UP_LOCATION = 'PICK_UP_LOCATION',
  PRODUCT_COUNT_IN_PACKAGES = 'PRODUCT_COUNT_IN_PACKAGES',
  PRODUCT_EXCLUSION = 'PRODUCT_EXCLUSION',
  PRODUCT_IDS = 'PRODUCT_IDS',
  RECIPIENT_COUNTRIES = 'RECIPIENT_COUNTRIES',
  RECIPIENT_STATES = 'RECIPIENT_STATES',
  SHIPPING_FACILITY = 'SHIPPING_FACILITY',
  SHIPPING_SPEEDS = 'SHIPPING_SPEEDS',
  TAXONOMY_IDS = 'TAXONOMY_IDS',
}

export enum IManifestPackageSort {
  CARRIER_SPEED = 'CARRIER_SPEED',
  PACKAGE_ID = 'PACKAGE_ID',
  PRODUCT = 'PRODUCT',
  RECIPIENT = 'RECIPIENT',
}

export type IManifestTable = {
  __typename?: 'ManifestTable'
  corporateOrders?: Maybe<Array<IPackage>>
  facilities?: Maybe<Array<IPackage>>
  metadata?: Maybe<IManifestTableMetadata>
  orderTypes?: Maybe<Array<IPackage>>
  packages?: Maybe<IPaginatedPackage>
  subproductsInPackages?: Maybe<Array<ISubproductsInPackage>>
}

export type IManifestTablePackagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}

export type IManifestTableMetadata = {
  __typename?: 'ManifestTableMetadata'
  aggregates: Array<IManifestAggregate>
  sorter: IManifestTableSortArgument
}

export type IManifestTableMetadataAggregatesArgs = {
  auxiliaries?: InputMaybe<Scalars['Boolean']['input']>
  corporateOrders?: InputMaybe<Scalars['Boolean']['input']>
  facilities?: InputMaybe<Scalars['Boolean']['input']>
  labels?: InputMaybe<Scalars['Boolean']['input']>
  orderTypes?: InputMaybe<Scalars['Boolean']['input']>
  products?: InputMaybe<Scalars['Boolean']['input']>
}

export type IManifestTableSortArgument = {
  __typename?: 'ManifestTableSortArgument'
  sortAscending: Scalars['Boolean']['output']
  sortField: IManifestPackageSort
}

export type IMegaphone = {
  __typename?: 'Megaphone'
  actionButtonText?: Maybe<Scalars['String']['output']>
  activeUntil?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  megaphoneType: Scalars['String']['output']
  messageBody: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type IMerchant = {
  __typename?: 'Merchant'
  effectiveShortName: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  packingSlipLogoImageUrl?: Maybe<Scalars['String']['output']>
}

export enum IMerchantContactRole {
  BUSINESS_CONTACT = 'business_contact',
  CUSTOMER_SERVICE = 'customer_service',
  EMERGENCY_CONTACT = 'emergency_contact',
  FINANCE = 'finance',
  MARKETING = 'marketing',
  OPERATIONS_FULFILLMENT = 'operations_fulfillment',
  OTHER = 'other',
}

export type IMerchantNotificationContact = {
  __typename?: 'MerchantNotificationContact'
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  mdashAccount?: Maybe<IMDashAccount>
  phoneNumber: Scalars['String']['output']
  role: IMerchantContactRole
}

export type IMerchantNotificationContactInput = {
  _destroy?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  role?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of MerchantNotificationSettings */
export type IMerchantNotificationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  merchantNotificationContactsAttributes?: InputMaybe<Array<IMerchantNotificationContactInput>>
  shippersAttributes?: InputMaybe<Array<IShipperInput>>
  smsNotificationEnabled: Scalars['Boolean']['input']
}

/** Autogenerated return type of MerchantNotificationSettings */
export type IMerchantNotificationSettingsPayload = {
  __typename?: 'MerchantNotificationSettingsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  mdashAccount?: Maybe<IMDashAccount>
}

export type IMerchantSku = {
  __typename?: 'MerchantSku'
  name: Scalars['String']['output']
  quantity: Scalars['Float']['output']
}

export type IMerchantUser = {
  __typename?: 'MerchantUser'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  mdashAccount: IMDashAccount
  name: Scalars['String']['output']
  permissions: IMerchantUserPermissionSet
}

export type IMerchantUserAccessLogAggregate = {
  __typename?: 'MerchantUserAccessLogAggregate'
  data: Array<IMerchantUserAccessLogDataPoint>
  id: Scalars['String']['output']
  user?: Maybe<IMerchantUser>
}

export type IMerchantUserAccessLogDataPoint = {
  __typename?: 'MerchantUserAccessLogDataPoint'
  day: Scalars['ISO8601Date']['output']
  value: Scalars['Int']['output']
  x: Scalars['ISO8601Date']['output']
  y?: Maybe<Scalars['Int']['output']>
}

export type IMerchantUserPermissionSet = {
  __typename?: 'MerchantUserPermissionSet'
  canCancelSuborders?: Maybe<Scalars['Boolean']['output']>
  canEditDeliveryDate?: Maybe<Scalars['Boolean']['output']>
  canEditGiftMessages?: Maybe<Scalars['Boolean']['output']>
  canManageSubproductLimits?: Maybe<Scalars['Boolean']['output']>
  canUseGuac?: Maybe<Scalars['Boolean']['output']>
  canViewIssues?: Maybe<Scalars['Boolean']['output']>
  canViewPerformance?: Maybe<Scalars['Boolean']['output']>
  canViewProducts?: Maybe<Scalars['Boolean']['output']>
  canViewPurchaser?: Maybe<Scalars['Boolean']['output']>
  canViewStatements?: Maybe<Scalars['Boolean']['output']>
}

export type IMetabaseReport = {
  __typename?: 'MetabaseReport'
  breadcrumbs?: Maybe<Array<IMetabaseReportBreadcrumb>>
  calculatedInput?: Maybe<Array<IMetabaseReportInput>>
  dashboardId?: Maybe<Scalars['ID']['output']>
  enableDateFilters?: Maybe<Scalars['Boolean']['output']>
  enableMerchantsFilter?: Maybe<Scalars['Boolean']['output']>
  endDate?: Maybe<Scalars['ISO8601Date']['output']>
  error?: Maybe<Scalars['String']['output']>
  htmlControlInput?: Maybe<Array<IMetabaseReportInput>>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['ISO8601Date']['output']>
  uiFlags?: Maybe<Array<IMetabaseReportUiFlag>>
  url?: Maybe<Scalars['String']['output']>
  useMetabase?: Maybe<Scalars['Boolean']['output']>
  userInput?: Maybe<Array<IMetabaseReportInput>>
}

export type IMetabaseReportBreadcrumb = {
  __typename?: 'MetabaseReportBreadcrumb'
  title: Scalars['String']['output']
}

export type IMetabaseReportCategory = {
  __typename?: 'MetabaseReportCategory'
  category: Scalars['String']['output']
  displayName: Scalars['String']['output']
  displayOrder: Scalars['Int']['output']
  reports?: Maybe<Array<IMetabaseReport>>
  showSubmenu: Scalars['Boolean']['output']
}

export type IMetabaseReportInput = {
  __typename?: 'MetabaseReportInput'
  defaultValue?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputType?: Maybe<Scalars['String']['output']>
  metabaseReportId: Scalars['ID']['output']
  reportParameterName: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type IMetabaseReportUiFlag = {
  __typename?: 'MetabaseReportUiFlag'
  name: Scalars['String']['output']
  value?: Maybe<Scalars['String']['output']>
}

export type IMetadata = {
  __typename?: 'Metadata'
  currentPage: Scalars['Int']['output']
  limitValue: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  totalPages: Scalars['Int']['output']
}

export type IMissedShipment = {
  __typename?: 'MissedShipment'
  /** Total missed shipment cost (shipping and products) as a formatted currency string */
  adjustmentCost?: Maybe<Scalars['String']['output']>
  /** Total missed shipment cost (shipping and products) as cents */
  adjustmentCostInCents: Scalars['Int']['output']
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  missedShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  newShipOn: Scalars['ISO8601Date']['output']
  newShipSpeed?: Maybe<Scalars['String']['output']>
  orderId: Scalars['ID']['output']
  originalShipSpeed?: Maybe<Scalars['String']['output']>
  packageId: Scalars['ID']['output']
  productNames: Array<Scalars['String']['output']>
}

export type IMissedShipmentIssue = IIssueInterface & {
  __typename?: 'MissedShipmentIssue'
  adjustmentsCost: Scalars['String']['output']
  adjustmentsInCents: Scalars['Int']['output']
  category: Scalars['String']['output']
  label: Scalars['String']['output']
  missedShipments?: Maybe<Array<IMissedShipment>>
  numberOfIssues: Scalars['Int']['output']
  numberOfPackages: Scalars['Int']['output']
  percentOfPackagesWithIssues: Scalars['Float']['output']
}

export type IMutation = {
  __typename?: 'Mutation'
  addPromotionalCodeToOrder?: Maybe<IAddPromotionalCodeToOrderPayload>
  assignPackagesToFacility?: Maybe<IAssignPackagesToFacilityPayload>
  assignProductToTaxonomy?: Maybe<IAssignProductToTaxonomyPayload>
  batchPrint?: Maybe<IBatchPrintPayload>
  cancelPackage?: Maybe<ICancelPackagePayload>
  copyPackage?: Maybe<ICopyPackagePayload>
  createAutoAssignmentRule?: Maybe<ICreateAutoAssignmentRulePayload>
  createCartItem?: Maybe<ICreateCartItemPayload>
  createOrder?: Maybe<ICreateOrderPayload>
  createTaxonomy?: Maybe<ICreateTaxonomyPayload>
  discardPackagesShippingLabels?: Maybe<IDiscardPackagesShippingLabelsPayload>
  discardShippingLabel?: Maybe<IDiscardShippingLabelPayload>
  editDeliveryDate?: Maybe<IEditDeliveryDatePayload>
  editGiftMessage?: Maybe<IEditGiftMessagePayload>
  login?: Maybe<ILoginPayload>
  logout?: Maybe<ILogoutPayload>
  merchantNotificationSettings?: Maybe<IMerchantNotificationSettingsPayload>
  placeOrder?: Maybe<IPlaceOrderPayload>
  removeAutoAssignmentRule?: Maybe<IRemoveAutoAssignmentRulePayload>
  removeCartItem?: Maybe<IRemoveCartItemPayload>
  removeProductFromTaxonomy?: Maybe<IRemoveProductFromTaxonomyPayload>
  removePromotionalCodeFromOrder?: Maybe<IRemovePromotionalCodeFromOrderPayload>
  updateAutoAssignmentRule?: Maybe<IUpdateAutoAssignmentRulePayload>
  updateCartItem?: Maybe<IUpdateCartItemPayload>
  updateDeliveryDate?: Maybe<IUpdateDeliveryDatePayload>
  updateGiftMessage?: Maybe<IUpdateGiftMessagePayload>
  updatePantryItemLimit?: Maybe<IUpdatePantryItemLimitPayload>
  updatePurchaserInfo?: Maybe<IUpdatePurchaserInfoPayload>
  updateShipper?: Maybe<IUpdateShipperPayload>
  updateShippingAddress?: Maybe<IUpdateShippingAddressPayload>
  updateTaxonomy?: Maybe<IUpdateTaxonomyPayload>
}

export type IMutationAddPromotionalCodeToOrderArgs = {
  input: IAddPromotionalCodeToOrderInput
}

export type IMutationAssignPackagesToFacilityArgs = {
  input: IAssignPackagesToFacilityInput
}

export type IMutationAssignProductToTaxonomyArgs = {
  input: IAssignProductToTaxonomyInput
}

export type IMutationBatchPrintArgs = {
  input: IBatchPrintInput
}

export type IMutationCancelPackageArgs = {
  input: ICancelPackageInput
}

export type IMutationCopyPackageArgs = {
  input: ICopyPackageInput
}

export type IMutationCreateAutoAssignmentRuleArgs = {
  input: ICreateAutoAssignmentRuleInput
}

export type IMutationCreateCartItemArgs = {
  input: ICreateCartItemInput
}

export type IMutationCreateOrderArgs = {
  input: ICreateOrderInput
}

export type IMutationCreateTaxonomyArgs = {
  input: ICreateTaxonomyInput
}

export type IMutationDiscardPackagesShippingLabelsArgs = {
  input: IDiscardPackagesShippingLabelsInput
}

export type IMutationDiscardShippingLabelArgs = {
  input: IDiscardShippingLabelInput
}

export type IMutationEditDeliveryDateArgs = {
  input: IEditDeliveryDateInput
}

export type IMutationEditGiftMessageArgs = {
  input: IEditGiftMessageInput
}

export type IMutationLoginArgs = {
  input: ILoginInput
}

export type IMutationLogoutArgs = {
  input: ILogoutInput
}

export type IMutationMerchantNotificationSettingsArgs = {
  input: IMerchantNotificationSettingsInput
}

export type IMutationPlaceOrderArgs = {
  input: IPlaceOrderInput
}

export type IMutationRemoveAutoAssignmentRuleArgs = {
  input: IRemoveAutoAssignmentRuleInput
}

export type IMutationRemoveCartItemArgs = {
  input: IRemoveCartItemInput
}

export type IMutationRemoveProductFromTaxonomyArgs = {
  input: IRemoveProductFromTaxonomyInput
}

export type IMutationRemovePromotionalCodeFromOrderArgs = {
  input: IRemovePromotionalCodeFromOrderInput
}

export type IMutationUpdateAutoAssignmentRuleArgs = {
  input: IUpdateAutoAssignmentRuleInput
}

export type IMutationUpdateCartItemArgs = {
  input: IUpdateCartItemInput
}

export type IMutationUpdateDeliveryDateArgs = {
  input: IUpdateDeliveryDateInput
}

export type IMutationUpdateGiftMessageArgs = {
  input: IUpdateGiftMessageInput
}

export type IMutationUpdatePantryItemLimitArgs = {
  input: IUpdatePantryItemLimitInput
}

export type IMutationUpdatePurchaserInfoArgs = {
  input: IUpdatePurchaserInfoInput
}

export type IMutationUpdateShipperArgs = {
  input: IUpdateShipperInput
}

export type IMutationUpdateShippingAddressArgs = {
  input: IUpdateShippingAddressInput
}

export type IMutationUpdateTaxonomyArgs = {
  input: IUpdateTaxonomyInput
}

export type IOrder = {
  __typename?: 'Order'
  appliedGiftCards: Array<IGiftCard>
  appliedPromotion?: Maybe<IAppliedPromotion>
  cartItems: Array<ICartItem>
  completedByCustomerAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  deliveryCity?: Maybe<Scalars['String']['output']>
  deliveryCompany?: Maybe<Scalars['String']['output']>
  deliveryCountry?: Maybe<Scalars['String']['output']>
  deliveryEmail?: Maybe<Scalars['String']['output']>
  deliveryFirstName?: Maybe<Scalars['String']['output']>
  deliveryLastName?: Maybe<Scalars['String']['output']>
  deliveryPhone?: Maybe<Scalars['String']['output']>
  deliveryPostalCode?: Maybe<Scalars['String']['output']>
  deliveryState?: Maybe<Scalars['String']['output']>
  deliveryStreet1?: Maybe<Scalars['String']['output']>
  deliveryStreet2?: Maybe<Scalars['String']['output']>
  discountInCents: Scalars['Int']['output']
  giftMessage?: Maybe<Scalars['String']['output']>
  giftMessageFrom?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isCompleted?: Maybe<Scalars['Boolean']['output']>
  isGuacOrder: Scalars['Boolean']['output']
  isReadyForCheckout: Scalars['Boolean']['output']
  isValidShippingAddress: Scalars['Boolean']['output']
  packages?: Maybe<Array<IPackage>>
  processedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** alias of contents_subtotal */
  productsSubtotalInCents: Scalars['Int']['output']
  purchaserAccountId?: Maybe<Scalars['ID']['output']>
  purchaserEmail?: Maybe<Scalars['String']['output']>
  purchaserFirstName?: Maybe<Scalars['String']['output']>
  purchaserLastName?: Maybe<Scalars['String']['output']>
  purchaserName?: Maybe<Scalars['String']['output']>
  purchaserPhone?: Maybe<Scalars['String']['output']>
  recipientEmails?: Maybe<Array<Scalars['String']['output']>>
  shippingSubtotalInCents: Scalars['Int']['output']
  taxInCents: Scalars['Int']['output']
  totalInCents: Scalars['Int']['output']
  whitelabelMerchant?: Maybe<IMerchant>
}

export enum IOrderType {
  CORPORATE = 'CORPORATE',
  IN_STORE_PAYMENT = 'IN_STORE_PAYMENT',
  MARKETPLACE = 'MARKETPLACE',
  RESHIPMENT = 'RESHIPMENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  WHITELABEL = 'WHITELABEL',
}

export type IPackage = {
  __typename?: 'Package'
  amDeliveryLabel?: Maybe<Scalars['String']['output']>
  amDeliverySurchargeInCents?: Maybe<Scalars['Int']['output']>
  cartItems: Array<ICartItem>
  completedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  corporateOrderCompanyName?: Maybe<Scalars['String']['output']>
  currentShippingLabel?: Maybe<IShippingLabel>
  deliveryCity?: Maybe<Scalars['String']['output']>
  deliveryCompany?: Maybe<Scalars['String']['output']>
  deliveryCountry: Scalars['String']['output']
  deliveryFirstName?: Maybe<Scalars['String']['output']>
  deliveryFullName?: Maybe<Scalars['String']['output']>
  deliveryLastName?: Maybe<Scalars['String']['output']>
  deliveryPhone?: Maybe<Scalars['String']['output']>
  deliveryPostalCode?: Maybe<Scalars['String']['output']>
  deliveryState?: Maybe<Scalars['String']['output']>
  deliveryStreet1?: Maybe<Scalars['String']['output']>
  deliveryStreet2?: Maybe<Scalars['String']['output']>
  gbmcState: Scalars['String']['output']
  giftMessage?: Maybe<IGiftMessage>
  id: Scalars['ID']['output']
  isAmDelivery?: Maybe<Scalars['Boolean']['output']>
  isAmDeliveryEnabled?: Maybe<Scalars['Boolean']['output']>
  isCancelable?: Maybe<Scalars['Boolean']['output']>
  isCorporateOrder: Scalars['Boolean']['output']
  isDeliveryDateEditable?: Maybe<Scalars['Boolean']['output']>
  isGiftMessageEditable?: Maybe<Scalars['Boolean']['output']>
  isInStoreOrder: Scalars['Boolean']['output']
  /** When package is reshipped due to Merchant's fault */
  isReshipment: Scalars['Boolean']['output']
  isSubscription: Scalars['Boolean']['output']
  isUpchargeable?: Maybe<Scalars['Boolean']['output']>
  isWhitelabel: Scalars['Boolean']['output']
  labelNeedsDiscard?: Maybe<Scalars['Boolean']['output']>
  labelNeedsReprint?: Maybe<Scalars['Boolean']['output']>
  lineItems: Array<ILineItem>
  mdashAccountId: Scalars['String']['output']
  order: IOrder
  orderId: Scalars['ID']['output']
  packingSheetPrintedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  pickUpLocation?: Maybe<IPickUpLocation>
  products: Array<IProduct>
  purchaserEmail?: Maybe<Scalars['String']['output']>
  purchaserName?: Maybe<Scalars['String']['output']>
  purchaserPhone?: Maybe<Scalars['String']['output']>
  qrCodeImageUrl?: Maybe<Scalars['String']['output']>
  requestedDeliveryOn?: Maybe<Scalars['ISO8601Date']['output']>
  requiredDryIceWeightInPounds?: Maybe<Scalars['Float']['output']>
  reshipment?: Maybe<IPackage>
  shipperName: Scalars['String']['output']
  shippingCalendarData?: Maybe<Array<IShippingOptionData>>
  shippingChargedToCustomerInCents: Scalars['Int']['output']
  shippingFacility?: Maybe<IShippingFacility>
  toShipOn?: Maybe<Scalars['ISO8601Date']['output']>
}

export type IPaginatedPackage = {
  __typename?: 'PaginatedPackage'
  collection: Array<IPackage>
  metadata: IMetadata
}

export type IPantryItemLimit = {
  __typename?: 'PantryItemLimit'
  currentQuantity: Scalars['Int']['output']
  isShippable: Scalars['Boolean']['output']
  limit?: Maybe<Scalars['Int']['output']>
  pantryItemId: Scalars['ID']['output']
  pantryItemName: Scalars['String']['output']
  toShipOn: Scalars['ISO8601Date']['output']
}

export type IPickUpLocation = {
  __typename?: 'PickUpLocation'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of PlaceOrder */
export type IPlaceOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  giftCardPayment?: InputMaybe<Scalars['Boolean']['input']>
  inStorePayment?: InputMaybe<Scalars['Boolean']['input']>
  nameOnCard?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  stripeToken?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of PlaceOrder */
export type IPlaceOrderPayload = {
  __typename?: 'PlaceOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

export type IProduct = {
  __typename?: 'Product'
  id: Scalars['ID']['output']
  isAvailable: Scalars['Boolean']['output']
  isSoldOut: Scalars['Boolean']['output']
  isWhitelabel: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  priceInCents?: Maybe<Scalars['Int']['output']>
  quantityLimit?: Maybe<Scalars['Int']['output']>
  sku?: Maybe<Scalars['String']['output']>
  variances?: Maybe<Array<IVariance>>
}

export type IProductTaxonomy = {
  __typename?: 'ProductTaxonomy'
  id: Scalars['ID']['output']
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  productId: Scalars['ID']['output']
  taxonomyId: Scalars['ID']['output']
}

export type IQuery = {
  __typename?: 'Query'
  accessLogs: Array<IMerchantUserAccessLogAggregate>
  batchPrintActivity: Array<IBatchPrintActivityAggregate>
  dailyPrintActivity: Array<IDailyPrintActivityAggregate>
  identity: IIdentity
  invoiceAdjustmentsReport?: Maybe<Array<IInvoiceAdjustment>>
  invoiceCancellationsReport?: Maybe<Array<IInvoiceCancellation>>
  invoiceGiftCardRedemptionsReport?: Maybe<Array<IInvoiceGiftCardRedemption>>
  invoiceMissedShipmentsReport?: Maybe<Array<IInvoiceMissedShipment>>
  invoicePromotionsReport?: Maybe<Array<IInvoicePromotion>>
  invoiceRefundsReport?: Maybe<Array<IInvoiceRefund>>
  invoiceReshipmentsReport?: Maybe<Array<IInvoiceReshipment>>
  invoiceSalesMetabaseReport?: Maybe<IExtendedMetabaseReport>
  lineItemsCustomerChoices: Array<ILineItemCustomerChoice>
  manifestCalendar: Array<IManifestCalendarDay>
  manifestCarrierInfo: IManifestCarrierInfo
  manifestPackages: IManifestTable
  mdashAccount?: Maybe<IMDashAccount>
  /** Top of the page notice */
  megaphone?: Maybe<IMegaphone>
  /** Modal Megaphone */
  megaphoneModal?: Maybe<IMegaphone>
  merchants: Array<IMerchant>
  metabaseReport?: Maybe<IExtendedMetabaseReport>
  metabaseReportsByCategory?: Maybe<Array<IMetabaseReportCategory>>
  metabaseReportsFor?: Maybe<Array<IMetabaseReport>>
  missedShipmentIssues: IMissedShipmentIssue
  order: IOrder
  package: IPackage
  pantryItemLimits?: Maybe<Array<IPantryItemLimit>>
  purchaserInfo: Array<IPackage>
  refundIssues: IRefundIssue
  reshipmentIssues: IReshipmentIssue
  searchAccounts: Array<IMDashAccount>
  searchPackages: Array<IPackage>
  shipperInvoices?: Maybe<Array<IShipperInvoice>>
  shippers: Array<IShipper>
  states: Array<IState>
  subproductSummary: ISubproductSummary
}

export type IQueryAccessLogsArgs = {
  aggregateBy?: InputMaybe<IAccessLogEntity>
}

export type IQueryBatchPrintActivityArgs = {
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryDailyPrintActivityArgs = {
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryInvoiceAdjustmentsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceCancellationsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceGiftCardRedemptionsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceMissedShipmentsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoicePromotionsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceRefundsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceReshipmentsReportArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInvoiceSalesMetabaseReportArgs = {
  invoiceId: Scalars['ID']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  metabaseEnv?: InputMaybe<Scalars['String']['input']>
}

export type IQueryLineItemsCustomerChoicesArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryManifestCalendarArgs = {
  interval?: InputMaybe<IManifestCalendarInterval>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryManifestCarrierInfoArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryManifestPackagesArgs = {
  corporateOrderCompanyNames?: InputMaybe<Array<Scalars['String']['input']>>
  facilityId?: InputMaybe<Scalars['ID']['input']>
  facilityType?: InputMaybe<IFacilityType>
  includePrintedPackages?: InputMaybe<Scalars['Boolean']['input']>
  labelPrintStatus?: InputMaybe<IShippingLabelStatus>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  orderTypes?: InputMaybe<Array<IOrderType>>
  packageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  productCountInPackages?: InputMaybe<Array<Scalars['Int']['input']>>
  productExclusion?: InputMaybe<Scalars['Boolean']['input']>
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>
  query?: InputMaybe<Scalars['String']['input']>
  recipientCountries?: InputMaybe<Array<Scalars['String']['input']>>
  recipientStates?: InputMaybe<Array<Scalars['String']['input']>>
  shippingSpeeds?: InputMaybe<Array<Scalars['String']['input']>>
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>
  sortField?: InputMaybe<IManifestPackageSort>
  toShipOn: Scalars['String']['input']
}

export type IQueryMdashAccountArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryMegaphoneModalArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryMerchantsArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryMetabaseReportArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  mdashAccountId: Scalars['ID']['input']
  merchantId?: InputMaybe<Scalars['ID']['input']>
  metabaseEnv?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type IQueryMetabaseReportsByCategoryArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryMetabaseReportsForArgs = {
  mdashAccountId: Scalars['ID']['input']
  reportType?: InputMaybe<Scalars['String']['input']>
}

export type IQueryMissedShipmentIssuesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type IQueryOrderArgs = {
  id: Scalars['ID']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPackageArgs = {
  id: Scalars['ID']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPantryItemLimitsArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  pantryItemIds?: InputMaybe<Array<Scalars['ID']['input']>>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryPurchaserInfoArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryRefundIssuesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type IQueryReshipmentIssuesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type IQuerySearchAccountsArgs = {
  query: Scalars['String']['input']
}

export type IQuerySearchPackagesArgs = {
  facilityId?: InputMaybe<Scalars['ID']['input']>
  facilityType?: InputMaybe<IFacilityType>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  query: Scalars['String']['input']
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IQueryShipperInvoicesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryShippersArgs = {
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySubproductSummaryArgs = {
  facilityId?: InputMaybe<Scalars['ID']['input']>
  facilityType?: InputMaybe<IFacilityType>
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  packageIds?: InputMaybe<Array<Scalars['ID']['input']>>
  toShipOn?: InputMaybe<Scalars['String']['input']>
}

export type IRefund = {
  __typename?: 'Refund'
  /** Total refund cost (shipping and products) as a formatted currency string */
  adjustmentCost?: Maybe<Scalars['String']['output']>
  /** Total refund cost (shipping and products) as cents */
  adjustmentCostInCents: Scalars['Int']['output']
  comment?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  orderId: Scalars['ID']['output']
  packageId: Scalars['ID']['output']
  productNames: Array<Scalars['String']['output']>
  reasonDescription: Scalars['String']['output']
  refundsShipping: Scalars['Boolean']['output']
  responsibleParty: Scalars['String']['output']
  shippedOn?: Maybe<Scalars['ISO8601Date']['output']>
}

export type IRefundIssue = IIssueInterface & {
  __typename?: 'RefundIssue'
  adjustmentsCost: Scalars['String']['output']
  adjustmentsInCents: Scalars['Int']['output']
  category: Scalars['String']['output']
  label: Scalars['String']['output']
  numberOfIssues: Scalars['Int']['output']
  numberOfPackages: Scalars['Int']['output']
  percentOfPackagesWithIssues: Scalars['Float']['output']
  refunds: Array<IRefund>
}

/** Autogenerated input type of RemoveAutoAssignmentRule */
export type IRemoveAutoAssignmentRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ruleId: Scalars['ID']['input']
  shipperId: Scalars['ID']['input']
}

/** Autogenerated return type of RemoveAutoAssignmentRule */
export type IRemoveAutoAssignmentRulePayload = {
  __typename?: 'RemoveAutoAssignmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shipper?: Maybe<IShipper>
}

/** Autogenerated input type of RemoveCartItem */
export type IRemoveCartItemInput = {
  cartItemId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of RemoveCartItem */
export type IRemoveCartItemPayload = {
  __typename?: 'RemoveCartItemPayload'
  cartItem?: Maybe<ICartItem>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  isSuccessful?: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated input type of RemoveProductFromTaxonomy */
export type IRemoveProductFromTaxonomyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId: Scalars['ID']['input']
  productTaxonomyId: Scalars['ID']['input']
}

/** Autogenerated return type of RemoveProductFromTaxonomy */
export type IRemoveProductFromTaxonomyPayload = {
  __typename?: 'RemoveProductFromTaxonomyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  productTaxonomy: IProductTaxonomy
}

/** Autogenerated input type of RemovePromotionalCodeFromOrder */
export type IRemovePromotionalCodeFromOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  code: Scalars['String']['input']
  orderId: Scalars['ID']['input']
}

/** Autogenerated return type of RemovePromotionalCodeFromOrder */
export type IRemovePromotionalCodeFromOrderPayload = {
  __typename?: 'RemovePromotionalCodeFromOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

export type IReshipment = {
  __typename?: 'Reshipment'
  /** Total reshipment cost (shipping and products) as a formatted currency string */
  adjustmentCost?: Maybe<Scalars['String']['output']>
  /** Total reshipment cost (shipping and products) in cents */
  adjustmentCostInCents: Scalars['Int']['output']
  comment?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  newShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  orderId?: Maybe<Scalars['ID']['output']>
  originalShipOn?: Maybe<Scalars['ISO8601Date']['output']>
  packageId?: Maybe<Scalars['ID']['output']>
  productNames: Array<Scalars['String']['output']>
  reshipmentDate: Scalars['ISO8601Date']['output']
}

export type IReshipmentIssue = IIssueInterface & {
  __typename?: 'ReshipmentIssue'
  adjustmentsCost: Scalars['String']['output']
  adjustmentsInCents: Scalars['Int']['output']
  category: Scalars['String']['output']
  label: Scalars['String']['output']
  numberOfIssues: Scalars['Int']['output']
  numberOfPackages: Scalars['Int']['output']
  percentOfPackagesWithIssues: Scalars['Float']['output']
  reshipments?: Maybe<Array<IReshipment>>
}

export type ISelectedProductOption = {
  __typename?: 'SelectedProductOption'
  productOption?: Maybe<IVarianceOption>
  productOptionId?: Maybe<Scalars['ID']['output']>
  variance?: Maybe<IVariance>
  varianceId?: Maybe<Scalars['ID']['output']>
}

export type IShipper = {
  __typename?: 'Shipper'
  facilityAutoAssignmentRules: Array<IFacilityAutoAssignmentRule>
  fulfillmentEmail?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mdashAccount?: Maybe<IMDashAccount>
  name?: Maybe<Scalars['String']['output']>
  products: Array<IProduct>
  receivesRealtimeNotifications?: Maybe<Scalars['Boolean']['output']>
  shipperFacilities: Array<IShipperFacility>
  usesAutoAssignmentV2: Scalars['Boolean']['output']
}

export type IShipperFacility = {
  __typename?: 'ShipperFacility'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type IShipperInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  receivesRealtimeNotifications?: InputMaybe<Scalars['Boolean']['input']>
}

export type IShipperInvoice = {
  __typename?: 'ShipperInvoice'
  adjustmentsTotal?: Maybe<Scalars['String']['output']>
  cancellationsTotal?: Maybe<Scalars['String']['output']>
  giftCardRedemptionsTotal?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invoiceableTotal?: Maybe<Scalars['String']['output']>
  mdashAccount?: Maybe<IMDashAccount>
  merchantName?: Maybe<Scalars['String']['output']>
  missedShipmentsTotal?: Maybe<Scalars['String']['output']>
  pdfDocumentUrl?: Maybe<Scalars['String']['output']>
  promotionsTotal?: Maybe<Scalars['String']['output']>
  refundsTotal?: Maybe<Scalars['String']['output']>
  reshipmentsTotal?: Maybe<Scalars['String']['output']>
  salesTotal?: Maybe<Scalars['String']['output']>
  statementEnd: Scalars['String']['output']
  statementStart: Scalars['String']['output']
  totalInCents?: Maybe<Scalars['Int']['output']>
}

export type IShippingFacility = {
  __typename?: 'ShippingFacility'
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type IShippingLabel = {
  __typename?: 'ShippingLabel'
  carrier?: Maybe<Scalars['String']['output']>
  carrierResponse?: Maybe<Scalars['String']['output']>
  customUrl?: Maybe<Scalars['String']['output']>
  discardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  displayWhitelabelLogoInPackingSlip?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  labelImageUrl?: Maybe<Scalars['String']['output']>
  packingSlipUrl?: Maybe<Scalars['String']['output']>
  printedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  serviceName?: Maybe<Scalars['String']['output']>
  trackingCode?: Maybe<Scalars['String']['output']>
  trackingLink?: Maybe<Scalars['String']['output']>
}

export enum IShippingLabelStatus {
  CANCELED = 'CANCELED',
  LABEL_ERROR = 'LABEL_ERROR',
  LABEL_PENDING = 'LABEL_PENDING',
  NEED_DISCARD = 'NEED_DISCARD',
  NEED_REPRINT = 'NEED_REPRINT',
  NOT_PRINTED = 'NOT_PRINTED',
  PRINTED = 'PRINTED',
  TO_SHIP = 'TO_SHIP',
}

export type IShippingOptionData = {
  __typename?: 'ShippingOptionData'
  carrierSpeed: Scalars['String']['output']
  deadlineAt: Scalars['ISO8601Date']['output']
  isSoldOut: Scalars['Boolean']['output']
  label: Scalars['String']['output']
  minPurchaseInCents?: Maybe<Scalars['Int']['output']>
  rateInCents: Scalars['Int']['output']
  requestedDeliveryOn: Scalars['ISO8601Date']['output']
  toShipOn: Scalars['ISO8601Date']['output']
}

export type IState = {
  __typename?: 'State'
  abbreviation: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ISubproductSummary = {
  __typename?: 'SubproductSummary'
  products: Array<ISubproductSummaryProduct>
  selectedPackages: Scalars['Int']['output']
  subproducts: Array<ISubproductSummarySubproduct>
  totalPackages: Scalars['Int']['output']
}

export type ISubproductSummaryProduct = {
  __typename?: 'SubproductSummaryProduct'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  quantity: Scalars['Int']['output']
  subproducts: Array<ISubproductSummarySubproduct>
}

export type ISubproductSummarySubproduct = {
  __typename?: 'SubproductSummarySubproduct'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  optional?: Maybe<Scalars['Boolean']['output']>
  quantity: Scalars['Float']['output']
  sku?: Maybe<Scalars['String']['output']>
}

export type ISubproductsInPackage = {
  __typename?: 'SubproductsInPackage'
  /** Package (Suborder) ID */
  id: Scalars['ID']['output']
  subproducts: Array<ISubproductSummarySubproduct>
}

export type ITaxonomy = {
  __typename?: 'Taxonomy'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** Autogenerated input type of UpdateAutoAssignmentRule */
export type IUpdateAutoAssignmentRuleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryState?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<IFacilityAutoAssignmentRuleMode>
  productId?: InputMaybe<Scalars['ID']['input']>
  ruleId: Scalars['ID']['input']
  shipperFacilityId?: InputMaybe<Scalars['ID']['input']>
  shipperId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateAutoAssignmentRule */
export type IUpdateAutoAssignmentRulePayload = {
  __typename?: 'UpdateAutoAssignmentRulePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shipper?: Maybe<IShipper>
}

/** Autogenerated input type of UpdateCartItem */
export type IUpdateCartItemInput = {
  cartItemId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  productId?: InputMaybe<Scalars['ID']['input']>
  quantityOrWeight?: InputMaybe<Scalars['Float']['input']>
  specialInstructions?: InputMaybe<Scalars['String']['input']>
  variances?: InputMaybe<Array<IVarianceInput>>
}

/** Autogenerated return type of UpdateCartItem */
export type IUpdateCartItemPayload = {
  __typename?: 'UpdateCartItemPayload'
  cartItem?: Maybe<ICartItem>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateDeliveryDate */
export type IUpdateDeliveryDateInput = {
  amDelivery?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryDate: Scalars['String']['input']
  orderId: Scalars['ID']['input']
  packageId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateDeliveryDate */
export type IUpdateDeliveryDatePayload = {
  __typename?: 'UpdateDeliveryDatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

/** Autogenerated input type of UpdateGiftMessage */
export type IUpdateGiftMessageInput = {
  /** The body of the message */
  body?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The sender of the message */
  from?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateGiftMessage */
export type IUpdateGiftMessagePayload = {
  __typename?: 'UpdateGiftMessagePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

/** Autogenerated input type of UpdatePantryItemLimit */
export type IUpdatePantryItemLimitInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  limit: Scalars['Int']['input']
  mdashAccountId?: InputMaybe<Scalars['ID']['input']>
  pantryItemId: Scalars['ID']['input']
  toShipOn: Scalars['String']['input']
}

/** Autogenerated return type of UpdatePantryItemLimit */
export type IUpdatePantryItemLimitPayload = {
  __typename?: 'UpdatePantryItemLimitPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  pantryItemLimits?: Maybe<Array<IPantryItemLimit>>
}

/** Autogenerated input type of UpdatePurchaserInfo */
export type IUpdatePurchaserInfoInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
  purchaserEmail: Scalars['String']['input']
  purchaserFirstName: Scalars['String']['input']
  purchaserLastName: Scalars['String']['input']
  purchaserPhone: Scalars['String']['input']
}

/** Autogenerated return type of UpdatePurchaserInfo */
export type IUpdatePurchaserInfoPayload = {
  __typename?: 'UpdatePurchaserInfoPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

/** Autogenerated input type of UpdateShipper */
export type IUpdateShipperInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  shipperId: Scalars['ID']['input']
  usesAutoAssignmentV2?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpdateShipper */
export type IUpdateShipperPayload = {
  __typename?: 'UpdateShipperPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  shipper?: Maybe<IShipper>
}

/** Autogenerated input type of UpdateShippingAddress */
export type IUpdateShippingAddressInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  deliveryCity: Scalars['String']['input']
  deliveryCompany?: InputMaybe<Scalars['String']['input']>
  deliveryFirstName: Scalars['String']['input']
  deliveryLastName: Scalars['String']['input']
  deliveryPhone?: InputMaybe<Scalars['String']['input']>
  deliveryPostalCode: Scalars['String']['input']
  deliveryState: Scalars['String']['input']
  deliveryStreet1: Scalars['String']['input']
  deliveryStreet2?: InputMaybe<Scalars['String']['input']>
  orderId: Scalars['ID']['input']
  recipientEmails?: InputMaybe<Array<Scalars['String']['input']>>
}

/** Autogenerated return type of UpdateShippingAddress */
export type IUpdateShippingAddressPayload = {
  __typename?: 'UpdateShippingAddressPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<IOrder>
}

/** Autogenerated input type of UpdateTaxonomy */
export type IUpdateTaxonomyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  mdashAccountId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  taxonomyId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateTaxonomy */
export type IUpdateTaxonomyPayload = {
  __typename?: 'UpdateTaxonomyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  taxonomy?: Maybe<ITaxonomy>
}

export type IVariance = {
  __typename?: 'Variance'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  options?: Maybe<Array<IVarianceOption>>
}

export type IVarianceInput = {
  productOptionId: Scalars['ID']['input']
  varianceId: Scalars['ID']['input']
}

export type IVarianceOption = {
  __typename?: 'VarianceOption'
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  priceAdjInCents: Scalars['Int']['output']
}
