import { FC } from 'react'
import { kebabCase } from 'lodash'
import classnames from 'classnames'

import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import Tooltip from 'src/components/02-molecules/Tooltip'

export interface IPackageTagListProps {
  packageId?: string
  isGrayscale?: boolean
  isInline?: boolean
  isCorporateOrder?: boolean
  isInStoreOrder?: boolean
  isReshipment?: boolean
  isSubscription?: boolean
  isWhitelabel?: boolean
  shipsToCountry?: string
  corporateOrderCompanyName?: string | null
  useTooltipOnMobile?: boolean
  className?: string
}

const PackageTagList: FC<IPackageTagListProps> = ({
  packageId,
  isGrayscale,
  isInline,
  isCorporateOrder,
  isInStoreOrder,
  isReshipment,
  isSubscription,
  isWhitelabel,
  shipsToCountry,
  corporateOrderCompanyName,
  useTooltipOnMobile = false,
  className,
}) => {
  const tags: PackageType[] = []
  if ( shipsToCountry === 'CA' ) tags.push( PackageType.SHIP_TO_CANADA ) // order matters
  if ( isCorporateOrder ) tags.push( PackageType.CORPORATE )
  if ( isInStoreOrder ) tags.push( PackageType.IN_STORE )
  if ( isReshipment ) tags.push( PackageType.RESHIPMENT )
  if ( isSubscription ) tags.push( PackageType.SUBSCRIPTION )
  if ( isWhitelabel ) tags.push( PackageType.WHITE_LABEL )

  if ( tags.length === 0 ) return null

  const tagList = (
    <ul
      className={classnames(
        'flex',
        { 'items-center flex-row flex-wrap gap-1': isInline },
        className
      )}
    >
      {tags.map(( v: PackageType ) => (
        <li key={`package-${packageId}-${kebabCase( v )}`}>
          <PackageTag
            packageType={v}
            isGrayscale={isGrayscale}
            label={
              v === PackageType.CORPORATE ? `Corporate: ${corporateOrderCompanyName}` : undefined
            }
          />
        </li>
      ))}
    </ul>
  )

  return (
    <>
      {useTooltipOnMobile && (
        <Tooltip
          hideButtonText
          triggerClassName="print:hidden md:hidden h-8 w-8"
          contentClassName="md:hidden"
        >
          {tagList}
        </Tooltip>
      )}
      <div
        className={classnames({ 'hidden md:block': useTooltipOnMobile })}
        data-testid="package-tags"
      >
        {tagList}
      </div>
    </>
  )
}

export default PackageTagList
