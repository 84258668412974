import { FC, FormEvent, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from 'src/components/01-atoms/Button'
import ProductSelectorTag from 'src/components/01-atoms/ProductSelectorTag'
import TextInput from 'src/components/01-atoms/TextInput'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'

interface IPromoCodeEntryFormProps {
  inputError?: string

  /**
   * The codes that have already been applied.
   */
  appliedCodes?: string[]

  /**
   * Method to call when a promo code is set to be added.
   */
  handleAdd?: ( code: string ) => void

  /**
   * Method to call when the promo code is set to be removed.
   */
  handleRemove?: ( code: string ) => void
}

const PromoCodeEntryForm: FC<IPromoCodeEntryFormProps> = ({
  inputError,
  appliedCodes = [],
  handleAdd = () => {},
  handleRemove = () => {},
}) => {
  const [ newCode, setNewCode ] = useState<string>( '' )

  const handleSubmit = ( e: FormEvent ) => {
    e.preventDefault()
    if ( !newCode || !handleAdd ) return
    handleAdd( newCode )
    setNewCode( '' )
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-2 justify-center sm:flex-row lg:flex-col xl:flex-row"
      >
        <TextInput
          labelText="Promo Code or Gift Card"
          wrapperClassName="grow flex-1"
          onChange={( e ) => setNewCode( e.currentTarget.value )}
          error={inputError}
          value={newCode}
          reduceLayoutShift
          showLabel
          outline
        />
        <Button
          type="submit"
          className="justify-end center h-10 gap-2 w-full sm:w-auto sm:mt-6 lg:w-full lg:mt-0 xl:w-auto xl:mt-6"
          data-testid="add-promo-code-button"
          disabled={!newCode}
          title={!newCode ? 'You must enter a code to be added.' : undefined}
        >
          <span className="sm:sr-only lg:not-sr-only xl:sr-only">Add</span>
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </Button>
      </form>
      {appliedCodes.length > 0 && (
        <div className="mt-2" data-testid="applied-codes">
          {appliedCodes.map(( v ) => (
            <div className="inline-block" key={`promo-code-gift-card-${v}`}>
              <ProductSelectorTag text={v} selected onClick={() => handleRemove( v )} />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default PromoCodeEntryForm
