import classNames from 'classnames'
import { FC } from 'react'
import './styles.css'

interface IProps {
  /**
   * A fraction of progress. E.g. 0.7 means 70% completion.
   */
  current: number

  /**
   * The total of the items to be completed.
   */
  total: number

  /**
   * Text to show above the loading bar.
   */
  titleText?: string

  /**
   * Flip to true to indicate task completion. LoadingBar will gracefully fade out.
   */
  isComplete?: boolean

  /**
   * Whether or not to show the number of .
   */
  showProgress?: boolean

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const LoadingBar: FC<IProps> = ({
  current = 0,
  total = 100,
  titleText,
  isComplete = false,
  showProgress = false,
  className,
}) => {
  const constrainedTotal = Math.max( 0, total )
  const constrainedCurrent = Math.max( 0, Math.min( current, constrainedTotal ))

  return (
    <div
      className={classNames(
        {
          'opacity-0': isComplete,
        },
        'transition-opacity duration-500 delay-1000 motion-reduce:transition-none',
        className
      )}
      data-testid="loading-bar"
    >
      {titleText && <p className="text-sm">{titleText}</p>}
      <div className="flex flex-row items-center gap-x-2">
        <div className="relative min-h-2 flex-grow">
          <div
            className={classNames(
              {
                'bg-green-500': constrainedCurrent === constrainedTotal && constrainedTotal > 0,
                'bg-gb-gray-300': constrainedCurrent < constrainedTotal || constrainedTotal === 0,
              },
              'absolute inset-0 min-h-2 h-full w-full rounded'
            )}
          />
          {constrainedCurrent < total && (
            <div
              style={{ width: `${( constrainedCurrent / constrainedTotal ) * 100}%` }}
              className="top-0 left-0 min-h-2 max-w-full bg-gradient-to-r from-gb-blue-300 to-gb-blue-600 rounded transition-all duration-200 shimmer motion-reduce:transition-none"
            />
          )}
        </div>
        {showProgress && (
          <div className="text-xs font-semibold">
            {Math.min( constrainedCurrent, constrainedTotal )} / {constrainedTotal}
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingBar
