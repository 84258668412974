import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useMutation } from 'urql'
import { loader } from 'graphql.macro'

import Container from 'src/components/01-atoms/Container'
import LoadingBar from 'src/components/01-atoms/LoadingBar'
import AlertList from 'src/components/02-molecules/AlertList'

import useAppParams from 'src/utils/hooks/useAppParams'
import {
  ICreateOrderMutation,
  ICreateOrderMutationVariables,
} from 'src/graphql/mutations/createOrder.types'

const createOrderMutation = loader( 'src/graphql/mutations/createOrder.graphql' )

const NewCheckout = () => {
  const { makeLinkUrls, mdashAccountId } = useAppParams()

  const [{ error, data, fetching }, createOrder ] = useMutation<
    ICreateOrderMutation,
    ICreateOrderMutationVariables
  >( createOrderMutation )

  const errors = [ error?.message ?? '', ...( data?.createOrder?.errors ?? []) ].filter(( e ) => !!e )

  useEffect(() => {
    if ( mdashAccountId && Number( mdashAccountId || 0 ) > 0 ) {
      createOrder({ mdashAccountId })
    }
  }, [])

  return (
    <Container className="py-6">
      {!fetching ? (
        <>
          {errors.length > 0 && !data?.createOrder?.order && <AlertList alerts={errors} />}
          {!!data?.createOrder?.order?.id && (
            <Navigate to={makeLinkUrls().checkout( data?.createOrder.order.id )} replace />
          )}
        </>
      ) : (
        !data?.createOrder?.order?.id && <LoadingBar current={99} total={100} />
      )}
    </Container>
  )
}

export default NewCheckout
