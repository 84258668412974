import { useMutation, UseMutationResponse } from 'urql'
import { loader } from 'graphql.macro'
import {
  IDiscardShippingLabelsMutation,
  IDiscardShippingLabelsMutationVariables,
} from 'src/graphql/mutations/discardShippingLabels.types'

const discardShippingLabels = loader( 'src/graphql/mutations/discardShippingLabels.graphql' )

type DiscardResponse = [
  UseMutationResponse<IDiscardShippingLabelsMutation, IDiscardShippingLabelsMutationVariables>[1],
  UseMutationResponse<IDiscardShippingLabelsMutation, IDiscardShippingLabelsMutationVariables>[0]
]

const useMarkLabelsDiscarded = (): DiscardResponse => {
  const [ discardResponse, markDiscarded ] = useMutation<
    IDiscardShippingLabelsMutation,
    IDiscardShippingLabelsMutationVariables
  >( discardShippingLabels )

  return [ markDiscarded, discardResponse ]
}

export default useMarkLabelsDiscarded
