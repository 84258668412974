import { FC, PropsWithChildren } from 'react'
import { useMatch } from 'react-router-dom'
import { faBoxHeart, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'

import useAppParams from 'src/utils/hooks/useAppParams'
import Container from 'src/components/01-atoms/Container'
import IconLink from 'src/components/01-atoms/IconLink'
import MainNavLink from 'src/components/01-atoms/MainNavLink'
import MainNavDropdown from 'src/components/02-molecules/MainNavDropdown'
import HamburgerNav from 'src/components/02-molecules/HamburgerNav'

import ProtectedByPermission from 'src/pages/elements/ProtectedByPermission'
import { IMainNavProps } from './interface'

const MainNav: FC<PropsWithChildren<IMainNavProps>> = ({ linkPrefix }) => {
  const { mdashAccountId, showStatements, reportLinks, makeLinkUrls, isAdmin } = useAppParams()
  const checkoutMatches = useMatch( ':mdashAccountId/checkout/*' )

  return (
    <nav
      className="border-b-2 border-gb-gray-400 min-h-9 center-v"
      data-testid="main-nav"
      aria-label="Main Navigation"
    >
      <Container>
        <div className="spread-h" data-testid="navbar">
          {mdashAccountId ? (
            <div className="gap-2 flex flex-row items-center">
              <HamburgerNav
                linkPrefix={linkPrefix}
                productsLinks={reportLinks?.productsLinks}
                performanceLinks={reportLinks?.performanceLinks}
                purchasersLinks={reportLinks?.purchasersLinks}
                showStatements={showStatements}
              />
              <MainNavLink to={`/${linkPrefix}/manifest`}>Orders</MainNavLink>
              {reportLinks?.productsLinks && (
                <MainNavDropdown title="Products" links={reportLinks?.productsLinks} />
              )}
              {reportLinks?.performanceLinks && (
                <MainNavDropdown title="Performance" links={reportLinks?.performanceLinks} />
              )}
              {reportLinks?.purchasersLinks && reportLinks?.purchasersLinks.length && (
                <MainNavLink to={reportLinks?.purchasersLinks[0].to}>
                  {reportLinks?.purchasersLinks[0].text}
                </MainNavLink>
              )}
              {showStatements && (
                <MainNavLink to={`/${linkPrefix}/statements`}>Statements</MainNavLink>
              )}
              {isAdmin && (
                <MainNavDropdown
                  title="Admin Tools"
                  links={[
                    { to: `/${linkPrefix}/auto_assignments`, text: 'Facility AutoAssignment' },
                    { to: `/${linkPrefix}/product_tags`, text: 'Product Tags' },
                  ]}
                />
              )}
              <ProtectedByPermission userPermissions={[ 'canUseGuac' ]}>
                <MainNavLink
                  to={makeLinkUrls().checkout( 'new' )}
                  forceActive={!!checkoutMatches}
                  isButton
                >
                  Place an Order
                </MainNavLink>
              </ProtectedByPermission>
            </div>
          ) : (
            <div className="gap-2 flex flex-row items-center">
              <HamburgerNav
                linkPrefix={linkPrefix}
                productsLinks={reportLinks?.productsLinks}
                performanceLinks={reportLinks?.performanceLinks}
                purchasersLinks={reportLinks?.purchasersLinks}
                showStatements={showStatements}
              />
              <MainNavLink to="/admin/printmap">Print Map</MainNavLink>
              <MainNavLink to="/admin/adoption">Adoption</MainNavLink>
              <MainNavLink to="/admin/sigma-example">Sigma Example</MainNavLink>
            </div>
          )}
          <div className="gap-3 lg:gap-8 flex flex-row items-end">
            <IconLink
              href="//swag.goldbelly.com/"
              target="_blank"
              icon={faBoxHeart}
              title="Order Swag"
            >
              Order Swag
            </IconLink>
            <IconLink
              href="//www.goldbelly.com/merchant_support/sso"
              target="_blank"
              icon={faQuestionCircle}
              title="FAQ"
            >
              FAQ
            </IconLink>
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default MainNav
