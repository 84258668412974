import { FC, InputHTMLAttributes, forwardRef, Ref } from 'react'
import classNames from 'classnames'

export interface ITextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  type?: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
  labelText?: string
  ref?: Ref<HTMLInputElement>
  outline?: boolean
  showLabel?: boolean
  error?: string
  helperText?: string
  wrapperClassName?: string

  /**
   * Whether or not space under the input box should be preserved to prevent too much layout shift.
   */
  reduceLayoutShift?: boolean
}

const TextInput: FC<ITextInputProps> = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      labelText,
      showLabel,
      className,
      wrapperClassName,
      outline = false,
      type = 'text',
      error,
      helperText,
      reduceLayoutShift = false,
      ...inputProps
    },
    ref
  ) => {
    const pattern = {
      tel: /^\d{10}$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      number: /\d*/,
      text: /.*/,
      password: /.*/,
      search: /.*/,
      url: /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/,
    }[type]

    return (
      <label htmlFor={inputProps.id} className={wrapperClassName}>
        <span
          className={classNames(
            {
              'sr-only': !showLabel,
            },
            'mb-1 inline-block text-gb-gray-900 font-weight-normal font-medium text-sm'
          )}
        >
          {labelText ?? inputProps.placeholder}
          {inputProps.required && ' *'}
        </span>
        <input
          ref={ref}
          type={type}
          pattern={pattern.toString().replaceAll( '/', '' )}
          className={classNames(
            'text-input w-full p-2 rounded text-sm focus:outline-gb-blue-600 bg-white placeholder:text-gb-gray-800 disabled:placeholder-shown:opacity-50 disabled:bg-gb-gray-100 invalid:border-red-600',
            {
              'border-2 border-gb-gray-400': outline,
              'border-red-600': outline && !!error,
            },
            className
          )}
          {...inputProps}
        />
        {( reduceLayoutShift || helperText || error ) && (
          <div className="mt-1 text-xs">
            {helperText && <span className="block text-gb-gray-800">{helperText}</span>}
            {error && <span className="text-red-600 error-message">{error}</span>}
            {reduceLayoutShift && <>&nbsp;</>}
          </div>
        )}
      </label>
    )
  }
)

export default TextInput
