import { FC, useState, useEffect, useContext } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import MDashHead from 'src/pages/elements/MDashHead'
import LoginForm from 'src/components/02-molecules/LoginForm'
import AlertList from 'src/components/02-molecules/AlertList'
import Login from 'src/components/05-pages/Login'

import UserContext, { IIdentity } from 'src/contexts/UserContext'

import useManageUserSession from 'src/utils/hooks/useManageUserSession'

interface ILoginFormData {
  email: string
  password: string
}

const LoginPage: FC = () => {
  const navigate = useNavigate()
  const { identity, setIdentity } = useContext( UserContext )
  const { isAdmin, mdashAccountId, userId } = identity
  const { register, handleSubmit, getValues } = useForm<ILoginFormData>()
  const {
    logUserIn,
    loginResponse: { data, fetching, error },
  } = useManageUserSession()
  const [ errors, setErrors ] = useState<string[]>()

  useEffect(() => {
    if ( fetching || !data ) return
    if ( error || ( data && data.login?.errors )) {
      const responseErrors: string[] = []
      if ( error?.message ) responseErrors.push( error.message )
      if ( data?.login?.errors ) responseErrors.push( ...data.login.errors )

      if ( responseErrors.length > 0 ) {
        setErrors( responseErrors )
        return
      }
    }

    const identityFromMutation = data.login?.identity

    if ( identityFromMutation ) {
      setIdentity(( prevState: IIdentity ) => ({
        ...prevState,
        userId: identityFromMutation.merchantUser?.id,
        userName: identityFromMutation.merchantUser?.name,
        mdashAccountId: identityFromMutation.merchantUser?.mdashAccount.id,
        mdashAccountName: identityFromMutation.merchantUser?.mdashAccount.name,
      }))
      navigate( `/${data.login?.identity?.merchantUser?.mdashAccount.id}/manifest`, {
        replace: true,
      })
    }
  }, [ fetching, data, error ])

  // mdashAccountId is set in the URL, thus it can fool the UserContext
  // userId however must be obtained from BE authentication
  // thus an authenticated mdashAccountId _must_ also has valid userId
  if ( isAdmin ) return <Navigate to="/admin/printmap" replace />
  if ( userId && mdashAccountId && !isAdmin )
    return <Navigate to={`/${mdashAccountId}/manifest`} replace />

  return (
    <Login>
      <MDashHead pageTitle="Sign In" />
      {errors && (
        <AlertList
          type="error"
          alerts={errors}
          prefaceText="The following errors occurred:"
          className="mb-6"
        />
      )}
      <LoginForm
        emailProps={register( 'email' )}
        passwordProps={register( 'password' )}
        handleSubmit={handleSubmit(() => logUserIn( getValues()))}
      />
    </Login>
  )
}

export default LoginPage
